<template>
  <div class="BroadcastPage">
    
  </div>
</template>

<script>
export default {
  name: 'BroadcastPage',
  data(){
    return {}
  },
  props: {},
  methods: {

  }
}
</script>

<style scoped lang="sass">


</style>
